import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import TextInput from "@kikoff/components/src/v1/inputs/TextInput";
import useErrorConsumer from "@kikoff/hooks/src/useErrorConsumer";
import useForm from "@kikoff/hooks/src/useForm";
import yup from "@kikoff/utils/src/yup";

import { StyledButton } from "@component/buttons/buttons";
import { joinWaitlist } from "@feature/waitlists";
import analytics from "@util/analytics";
import { l10nResources } from "@util/l10n";

import { Flow } from "../container";

import cashAdvanceMobileImage from "./cash_advance_hero_image.png";

import styles from "./styles.module.scss";

const l10n = l10nResources({
  en: `title = Get the cash you need
description = Get up to $250* in minutes. Sign up below to get started
completed-text = Thanks! We will let you know when cash advance is ready.
disclaimer = *Subject to terms and conditions based on eligibility requirements. Advances range from $50-$250. Transfer fees may apply
submit = Get started
`,
  es: `title = Get the cash you need
description = Get up to $250* in minutes. Sign up below to get started
completed-text = Thanks! We will let you know when cash advance is ready.
disclaimer = *Subject to terms and conditions based on eligibility requirements. Advances range from $50-$250. Transfer fees may apply
submit = Get started
`,
});

function Hero({ flow }: { flow: Flow }) {
  const dispatch = useDispatch();
  const error = useErrorConsumer();

  const [completed, setCompleted] = useState<boolean>(false);

  const { register, handleSubmit } = useForm(
    useMemo(
      () => ({
        schema: yup.object({
          email: yup.string().required("Enter your email"),
        }),
      }),
      []
    )
  );

  return (
    <>
      <section className={styles["hero-section"]}>
        <div className={styles["image-wrapper"]}>
          <img
            alt="Cash Advance mobile"
            className={styles["hero-phone"]}
            src={cashAdvanceMobileImage.src}
          />
        </div>
        {completed ? (
          <div className={styles["text-wrapper"]}>
            <h1 className={`${styles["hero-title"]} text:heading-1`}>
              {l10n.getString("completed-text")}
            </h1>
          </div>
        ) : (
          <div className={styles["text-wrapper"]}>
            <h1 className={`${styles["hero-title"]} text:heading-1`}>
              {l10n.getString("title")}
            </h1>
            <h4 className={`${styles["hero-subtitle"]} text:heading-4`}>
              {l10n.getString("description")}
            </h4>

            <form className={styles["hero-form"]}>
              <TextInput
                className="ml-3"
                data-cy="waitlist-email-input"
                label="Email"
                type="email"
                variant="outlined"
                {...register("email", {
                  include: ["valid", "error"],
                })}
              />
              <StyledButton
                action="isClicked"
                className={`${styles["email-button"]} mt-2 ml-3`}
                data-cy="submit-join-waitlist"
                Element="button"
                onClick={handleSubmit((_, data) => {
                  if (!data.email) {
                    return;
                  }

                  const queryParams = new URLSearchParams(
                    window.location.search
                  );
                  const propertiesParams = Object.fromEntries(
                    queryParams.entries()
                  );

                  dispatch(
                    joinWaitlist({
                      email: data.email,
                      name: "cash-advance",
                      properties: {
                        ...propertiesParams,
                        source: window.origin,
                      },
                    })
                  )
                    .then(() => {
                      analytics.convert("CashAdvanceLeadSignup", {
                        email: data.email,
                      });
                      setCompleted(true);
                    })
                    .catch((e) => {
                      error.throw(e);
                    });
                })}
                shape="round"
                styleType="active"
                type="submit"
                variant="primary"
              >
                {l10n.getString("submit")}
              </StyledButton>
            </form>
            <div className={`${styles["legal-subtext"]} text:small mt-2 ml-1`}>
              {l10n.getString("disclaimer")}
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default Hero;
