import React from "react";
import Head from "next/head";

import Hero from "@component/landing/cash_advance/hero";

import styles from "./styles.module.scss";

export type Flow = "waitlist" | "signup";

function KikoffCashAdvanceContainer({ flow }: { flow: Flow }) {
  return (
    <>
      <Head>
        <title>Oasis</title>
        <meta name="description" content="Cash Advance with benefits" />
      </Head>
      <div className={styles["cash-advance-landing-page-body"]}>
        <div className={styles["cash-advance-landing-page"]}>
          <Hero flow={flow} />
        </div>
      </div>
    </>
  );
}

export default KikoffCashAdvanceContainer;
