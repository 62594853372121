/**
 * @file Contains waitlist actions
 */

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { AppThunk } from "@store";

interface joinWaitlistProps {
  email?: string;
  name?: string;
  properties?: { [k: string]: string };
}

export const joinWaitlist = ({
  name,
  email,
  properties = null,
}: joinWaitlistProps): AppThunk<
  Promise<web.public_.IJoinWaitlistResponse>
> => () => {
  return webRPC.Waitlists.joinWaitlist({
    email,
    name,
    properties,
  }).then(
    handleProtoStatus({
      SUCCESS: (data) => data,
      _DEFAULT: handleFailedStatus("Offer not available"),
    })
  );
};
